.myCard {
    background-color: transparent;
    perspective: 1000px;
    -webkit-perspective: 1000px;
}

.innerCard {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    -webkit-transition: transform 0.8s;
    -o-transition: transform 0.8s;
    -moz-transition: transform 0.8s;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    cursor: pointer;
}

.myCard:hover .innerCard {
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
}

.frontSide,
.backSide {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    -webkit-perspective: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    backface-visibility: hidden;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 1rem;
    -moz-border-radius: 1rem;
    -webkit-border-radius: 1rem;
    color: white;
    box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
    -o-box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
    font-weight: 700;
}

.backSide {
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
}

.frontSide::before,
.backSide::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    content: '';
    width: 110%;
    height: 110%;
    position: absolute;
    z-index: -1;
    border-radius: 1em;
    -moz-border-radius: 1em;
    -webkit-border-radius: 1em;
    filter: blur(20px);
    animation: animate 5s linear infinite;
    -webkit-animation: animate 5s linear infinite;
}

@keyframes animate {
    0% {
        opacity: 0.3;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}