body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Only change scollbars for desktop screens*/
@media (min-width: 1080px) {
  /* This will affect the scrollbar globally */
  ::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    background: #888; /* color of the scroll thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the scroll thumb on hover */
  }
}

h1 {
  font-size: clamp(2rem, 4vw, 3rem);
}

h2 {
  font-size: clamp(1.75rem, 3.5vw, 2.75rem);
}

h3 {
  font-size: clamp(1.5rem, 3vw, 2.5rem);
}

h4 {
  font-size: clamp(1.25rem, 2.5vw, 2.25rem) !important;
}

h5 {
  font-size: clamp(1rem, 2vw, 2rem);
}

h6 {
  font-size: clamp(0.875rem, 1.75vw, 1.5rem) !important;
}

p {
  font-size: clamp(0.815rem, 1.25vw, .815rem) !important;
}
