.leaderboard-carousel {
    max-width: 80vw;
}

@media (max-width: 768px) {
    .leaderboard-carousel {
        max-width: 100vw;
    }
}

.control-dots{
    position: absolute;
    bottom: -6px !important;
}

.dot{
    width: 12px !important;
    height: 12px !important;
}

@media (prefers-color-scheme: light) {
    .dot{
        background: #121212 !important;
    }
}

@media (max-width: 600px) {
    .control-dots{
        position: absolute;
        bottom: -12px !important;
    }
}